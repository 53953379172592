import axios from "axios";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next"
import { MapContainer, TileLayer, Marker } from "react-leaflet";
import Swal from 'sweetalert2';

export default function DatosContacto({ config, lang }) {

    const { t } = useTranslation();

    const [nombre, setNombre] = useState('');
    const [telefono, setTelefono] = useState('');
    const [email, setEmail] = useState('');
    const [tema, setTema] = useState('');
    const [mensaje, setMensaje] = useState('');
    const [consentimiento, setConsentimiento] = useState(false);

    const [address, setAddress] = useState(config.direccion_fiscal + "," + config.codigo_postal_fiscal + "," + config.poblacion_fiscal);
    const [address2, setAddress2] = useState(config.poblacion_fiscal);
    const [coordinates, setCoordinates] = useState([]);

    useEffect(() => {
        if (address === "") {
            return;
        }

        axios
            .get(`https://nominatim.openstreetmap.org/search?format=json&q=${address}`)
            .then((res) => {
                if (res.data.length > 0) {
                    setCoordinates([res.data[0].lat, res.data[0].lon]);
                }
                else {
                    axios
                        .get(`https://nominatim.openstreetmap.org/search?format=json&q=${address2}`)
                        .then((res) => {
                            if (res.data.length > 0) {
                                setCoordinates([res.data[0].lat, res.data[0].lon]);
                            }
                        })
                        .catch((err) => {
                            console.error(err);
                        });
                }
            })
            .catch((err) => {
                console.error(err);
            });

    }, [address, address2]);

    const handleSubmit = (e) => {
        e.preventDefault();
        const url = e.target.action;

        var mensajeerror = "";

        if (nombre === "" || telefono === "" || email === "" || mensaje === "" || tema === "" || !consentimiento) {

            if (nombre === "") mensajeerror += `${t("Nombre")}<br/>`;
            if (telefono === "") mensajeerror += `${t('Teléfono')}<br/>`;
            if (email === "") mensajeerror += `${t('Email')}<br/>`;
            if (mensaje === "") mensajeerror += `${t('Mensaje')}<br/>`;
            if (tema === "") mensajeerror += `${t('Tema')}<br/>`;
            if (!consentimiento) mensajeerror += `${t("consentimiento")}<br/>`;

            Swal.fire({
                title: `${t('Error')}`,
                html: `${t('Todos los campos con * son obligatorios')}<br/>` + mensajeerror,
                timer: 2500,
                showConfirmButton: false,
                allowOutsideClick: false,
                allowEscapeClick: false
            });
        } else {
            let elnombre = nombre;
            let eltelefono = telefono;
            let elmail = email;
            let elmensaje = mensaje;
            let eltema = tema;
            let elidioma = document.getElementById("idioma").value;

            let token = document.getElementById('meta_token').getAttribute('content');
            const result = axios.post(url, {
                yourname: elnombre,
                Phone: eltelefono,
                contactEmail: elmail,
                contactMessage: eltema + "<br/>" + elmensaje,

                lang: elidioma
            }, {
                headers: {
                    'content-type': 'text/json',
                    'X-CSRF-TOKEN': token
                }
            })
                .then((response) => {
                    console.log(response);
                    if (response.data.type === "error") {
                        Swal.fire({
                            title: "KO!",
                            html: `${t('Contacto no enviado. Inténtalo más tarde.')}`,
                            timer: 1500,
                            showConfirmButton: false,
                            allowOutsideClick: false,
                        });
                    } else {
                        Swal.fire({
                            title: "OK!",
                            html: `${t('Contacto enviado. En breve te informaremos.')}`,
                            timer: 1500,
                            showConfirmButton: false,
                            allowOutsideClick: false,
                        }).then((result) => {
                            window.location.reload();
                        });
                    }
                });
        }
    };

    return (
        <>
            {/*--===== CONTACT AREA STARTS =======--*/}
            <div className="contact-inner-area sp4">
                <div className="container">
                    <div className="row">
                        <div className="m-auto col-lg-2">
                            <h5 data-aos="fade-left" data-aos-duration="800" className="aos-init aos-animate tituloredondeado">{t("Contacto")}</h5>
                        </div>
                    </div>
                    <div className="space40"></div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="contact-inner-boxarea">
                                <div className="row align-items-center">
                                    <div className="col-lg-8">
                                        <div className="contact-input-section">
                                            <h3>{t("Envíanos un mensaje")}</h3>
                                            <div className="space16"></div>
                                            <div className="space12"></div>
                                            <form className="noticia-individual" id="contactForm" action="/enviacontacto" onSubmit={handleSubmit}>
                                                <input type="hidden" name="lang" id="idioma" value={lang.toString().toUpperCase()} />
                                                <div className="row">
                                                    <div className="col-lg-6">
                                                        <div className="space20"></div>
                                                        <div className="input-area">
                                                            <input type="text" placeholder={t("Su nombre")} name="yourname" required="" value={nombre} onChange={(e) => setNombre(e.target.value)} />
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-6">
                                                        <div className="space20"></div>
                                                        <div className="input-area">
                                                            <input type="email" placeholder={t("Email")} name="contactEmail" required="" value={email} onChange={(e) => setEmail(e.target.value)} />
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-6">
                                                        <div className="space20"></div>
                                                        <div className="input-area">
                                                            <input type="number" placeholder={t("Su teléfono")} name="Phone" required="" value={telefono} onChange={(e) => setTelefono(e.target.value)} />
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-6">
                                                        <div className="space20"></div>
                                                        <div className="input-area">
                                                            <input type="text" placeholder={t("Su tema")} name="subject" required="" value={tema} onChange={(e) => setTema(e.target.value)} />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="space20"></div>
                                                        <div className="input-area">
                                                            <textarea placeholder={t("Mensaje")} name="contactMessage" required="" value={mensaje} onChange={(e) => setMensaje(e.target.value)}></textarea>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="space32"></div>
                                                        <div className="input-area">
                                                            <input type="checkbox" value="acepto" name="acepto" required="" checked={consentimiento} onChange={(e) => setConsentimiento(e.target.checked)} className="checkboxpersonalizado" /> <a href="condiciones">{t("Acepto los términos y condiciones")}</a>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="space32"></div>
                                                        <div className="input-area text-end">
                                                            <button type="submit" className="header-btn4">{t("Enviar")}</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="contact-box">
                                            <div className="icons">
                                                <i className="fa-regular fa-phone-volume"></i>
                                            </div>
                                            <div className="content">
                                                <p>{t("Llama")}</p>
                                                <a href={"tel:" + config.telefono_fiscal}>{config.telefono_fiscal}</a>
                                            </div>
                                        </div>
                                        <div className="space20"></div>
                                        <div className="contact-box">
                                            <div className="icons">
                                                <i className="fa-regular fa-envelope-open"></i>
                                            </div>
                                            <div className="content">
                                                <p>Email</p>
                                                <a href={"mailto:" + config.email_fiscal}>{config.email_fiscal}</a>
                                            </div>
                                        </div>
                                        <div className="space20"></div>
                                        <div className="contact-box">
                                            <div className="icons">
                                                <i class="fa-brands fa-whatsapp"></i>
                                            </div>
                                            <div className="content">
                                                <p>{t("Whatsapp")}</p>
                                                <a href="https://wa.me/+34 687 336 787">+34 687 336 787</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="space80"></div>

                    <div className="row">
                        <div className="col-lg-12">
                            <div className="maps-area">
                                {coordinates.length > 0 && (
                                    <MapContainer center={coordinates} zoom={16} scrollWheelZoom={false}>
                                        <TileLayer
                                            attribution='Imagery &copy; <a href="https://www.mapbox.com/">Mapbox</a>'
                                            url="https://api.mapbox.com/styles/v1/magnoapartments/clm9hgl2p015701pjd18p6vrv/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoibWFnbm9hcGFydG1lbnRzIiwiYSI6ImNsY29uNmx4bjAwMWszdm4yYmY5aDR5YngifQ.6pEwCqEnsjUoXQQxu-Tcng"
                                        />
                                        <Marker position={coordinates}>
                                        </Marker>
                                    </MapContainer>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*--===== CONTACT AREA ENDS =======--*/}
        </>
    )
}
