import { React } from "react";
import { useTranslation } from 'react-i18next';
import { LazyLoadImage } from "react-lazy-load-image-component";
import 'react-lazy-load-image-component/src/effects/blur.css';


const ListaDestacados = ({ ruta, destacadas, config, lang }) => {

    var preidioma = ""
    if (lang != 'es') preidioma = lang + "/";

    const { t } = useTranslation();

    let results = [];

    {/* ************* */ }

    return (
        <>
            {destacadas.forEach((propiedad, index) => {
                results = results.concat(
                    <div className="destacats-propietat" key={index}>
                        <a href={"/" + preidioma + t("apartamentos") + "-" + propiedad.url_destino + "/" + propiedad.url_dinamica}>
                            {
                                propiedad.foto_principal.length != 0
                                    ? <LazyLoadImage effect='blur' threshold={10} className="destacats-imatge-propietat" src={ruta + "/propiedades/" + propiedad.id + "/fotos/web/thumb/" + propiedad.foto_principal[0].nombre} alt={propiedad.foto_principal[0].texto_alt ? propiedad.foto_principal[0].texto_alt[lang] : ""} title={propiedad.foto_principal[0].texto_title ? propiedad.foto_principal[0].texto_title[lang] : ""} />
                                    : <LazyLoadImage effect='blur' threshold={10} className="destacats-imatge-propietat" src="/images/web/bedloop-nofoto.png" alt="" title="" />
                            }
                        </a>
                        <div className="destacats-text">
                            <span className="destacats-icones-propietat" style={{ color: "" + config.color_corporativo_web + "" }}>
                                <i className="fa-solid fa-bed"></i> {propiedad['num_habitaciones']} | <i className="fa-solid fa-users"></i> {propiedad['capacidad_maxima']} | <i className="fa-solid fa-bath"></i> {propiedad['num_banyos']}
                            </span>
                            <span className="destacats-titol-propietat"><a href={"/" + preidioma + t("apartamentos") + "-" + propiedad.url_destino + "/" + propiedad.url_dinamica}>{propiedad.nombre}</a></span>
                            <span className="destacats-ciutat-propietat"> {propiedad.ciudad}</span>
                            <a className="btn btn-primary" href={"/" + preidioma + t("apartamentos") + "-" + propiedad.url_destino + "/" + propiedad.url_dinamica} style={{ background: "" + config.color_corporativo_web + "", border: "1px solid" + config.color_corporativo_web }}>
                                {
                                    propiedad.by_request == 1
                                        ? t("Petición")
                                        : t("Reservar")
                                }
                            </a>
                        </div>

                    </div>
                )
            })}

            {results}
        </>
    );
};

const Destacados = ({ destinos, ruta, destacadas, elementosweb, config, lang }) => {

    const { t } = useTranslation();

    return (

        <div className="fons pt-5 potblur">
            {elementosweb.subtitulo_buscador && <h2 className="text-center mb-5">{elementosweb.subtitulo_buscador[lang]}</h2>}
            {elementosweb.subtitulo_propiedades && <h3 className="text-center">{elementosweb.subtitulo_propiedades[lang]}</h3>}

            <div className="destacats">
                <ListaDestacados
                    ruta={ruta}
                    destacadas={destacadas}
                    config={config}
                    lang={lang}
                />
            </div>
        </div>
    );
};

export default Destacados;
