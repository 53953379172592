import React from 'react';

import "./../../../../css/web/costadaurada/bootstrap.min.css";
import "./../../../../css/web/costadaurada/aos.css";
import "./../../../../css/web/costadaurada/magnific-popup.css";
import "./../../../../css/web/costadaurada/mobile.css";
import "./../../../../css/web/costadaurada/owlcarousel.min.css";
import "./../../../../css/web/costadaurada/sidebar.css";
import "./../../../../css/web/costadaurada/slick-slider.css";
import "./../../../../css/web/costadaurada/nice-select.css";
import "./../../../../css/web/costadaurada//main.css";

import Scripts from './Compartido/Scripts.jsx';
import Preloader from './Compartido/Preloader.jsx';
import Header from './Compartido/Header.jsx';
import Carousel2 from './Inicio/Carousel2.jsx';
import Servicios from './Inicio/Servicios.jsx';
import Noticias from './Inicio/Noticias.jsx';
import OtraGaleria from './Inicio/OtraGaleria.jsx';
import Footer from './Compartido/Footer.jsx';
import AreaContacto from './Inicio/AreaContacto.jsx';
import Objetivos from './Inicio/Objetivos.jsx';
import LaAsociacion from './Inicio/LaAsociacion.jsx';
import { Helmet } from 'react-helmet';
import NoticiasPortAventura from './Inicio/NoticiasPortAventura.jsx';

const Inicio = (props) => {

    return (
        <body className="homepage3-body">
            <Helmet>
                <title>{props.meta_titulo}</title>
                <meta name="description" content={props.meta_descripcion} />
                <meta name="keywords" content={props.meta_keywords} />

                <meta property="og:title" content={props.meta_titulo} />
                <meta property="og:description" content={props.meta_descripcion} />
                <meta property="og:image" content="imagen" />
                <meta property="og:type" content={"website"} />

                <meta name="twitter:card" content="sumary_large_image" />
                <meta name="twitter:site" content="@aaturistics" />
                <meta name="twitter:creator" content="@aaturistics" />
                <meta name="twitter:title" content={props.meta_titulo} />
                <meta name="twitter:description" content={props.meta_descripcion} />
                <meta name="twitter:image" content="imagen" />
                <meta name="twitter:url" content={props.websgrupo.url_fiscal} />
            </Helmet>
            <Scripts />
            <Preloader />
            <Header
                config={props.websgrupo}
                ruta={props.ruta}
                lang={props.lang}
                destinos={props.destinos}
                experiencias={props.experiencias}
                idiomas={props.idiomas}
            />
            <Carousel2
                destinos={props.destinos}
                d_ale={props.aleatori}
                ruta={props.ruta}
                tiposcasas={props.tiposcasas}
                poblaciones={props.poblaciones}
                lang={props.lang}
            />
            <Servicios />
            <Objetivos />
            <LaAsociacion />
            <Noticias
                noticias={props.noticias}
                ruta={props.ruta}
                lang={props.lang}
            />
            <NoticiasPortAventura
                noticias={props.noticiasPortAventura}
                ruta={props.ruta}
                lang={props.lang}
            />
            <AreaContacto
                config={props.websgrupo}
                lang={props.lang}
            />
            <OtraGaleria
                colaboradores={props.colaboradoreshome}
                ruta={props.ruta}
            />
            <Footer
                config={props.websgrupo}
                ruta={props.ruta}
                lang={props.lang}
            />
        </body>
    );
}

export default Inicio;
