import { useTranslation } from "react-i18next";

export default function LaAsociacion({ lang }) {

    const { t } = useTranslation();

    var preidioma = ""
    if (lang != 'es') preidioma = lang + "/";

    return (
        <>
            {/*--===== PROPERTY FACT AREA STARTS =======--*/}
            < div className="property2-section-area sp6" >
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="property-header heading2 space-margin60">
                                <div className="heading2">
                                    <h5 data-aos="fade-left" data-aos-duration="800" style={{ lineHeight: "30px" }}>{t("Asociación de apartamentos turísticos Costa Daurada y Terres de l’Ebre")}</h5>
                                    <div className="space20"></div>
                                    <h2 className="text-anime-style-3">{t("Un poco de historia")} <br className="d-lg-block d-none" /> </h2>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row align-items-center">
                        <div className="col-lg-8">
                            <div className="rotation-images">
                                <img src="/images/web/costadaurada/home/historia.jpg" alt="" />
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="property-accordion">
                                <div className="accordion accordion-flush" id="accordionFlushExample">
                                    <div className="accordion-item" data-aos="fade-left" data-aos-duration="800">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="true" aria-controls="flush-collapseOne">
                                                {t("La asociación")}
                                            </button>
                                        </h2>
                                        <div id="flush-collapseOne" className="accordion-collapse collapse show" data-bs-parent="#accordionFlushExample">
                                            <div className="accordion-body">{t("Nuestra Asociación está presidida por Joan Calvet, y representa el sector de apartamentos en los municipios de Costa Daurada y Terres de l'Ebre. Fue fundada el 19 de julio de 1977 y es una Asociación totalmente privada y sin ánimo de lucro.")}</div>
                                        </div>
                                    </div>
                                    <div className="space20"></div>
                                    <div className="accordion-item" data-aos="fade-left" data-aos-duration="1000">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                                {t("Alquiler")}
                                            </button>
                                        </h2>
                                        <div id="flush-collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                            <div className="accordion-body">{t("Disfruta de unas vacaciones hechas a tu medida y vive cómodamente en cualquiera de nuestros magníficos alojamientos. Sin prisas y a tu gusto. En família, en pareja o como prefieras. Tú solo tienes que escoger dónde quieres pasar tus vacaciones.")}</div>
                                        </div>
                                    </div>
                                    <div className="space20"></div>
                                    <div className="accordion-item" data-aos="fade-left" data-aos-duration="1100">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                                                {t("Su entorno")}
                                            </button>
                                        </h2>
                                        <div id="flush-collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                            <div className="accordion-body">{t("Es uno de los principales destinos turísticos del Mediterráneo. Múltiples son las opciones que ofrecen sus 202 kilómetros de litoral y los contrastes de las comarcas más interiores. La Costa Daurada y Terres de l'Ebre pone a disposición de sus visitantes una oferta turística de calidad.")}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
            {/*--===== PROPERTY FACT AREA ENDS =======--*/}
        </>
    )
}
