import { useTranslation } from "react-i18next";
import { useState } from "react";
import Swal from "sweetalert2";
import axios from "axios";

export default function TextoAsociate({ ruta, lang }) {
    const { t } = useTranslation();

    const [nombrecontacto, setNombreContacto] = useState('');
    const [telefono, setTelefono] = useState('');
    const [email, setEmail] = useState('');
    const [fax, setFax] = useState('');
    const [empresa, setEmpresa] = useState('');
    const [localidad, setLocalidad] = useState('');
    const [provincia, setProvincia] = useState('');
    const [actividad, setActividad] = useState('');
    const [mensaje, setMensaje] = useState('');
    const [consentimiento1, setConsentimiento1] = useState(false);
    const [consentimiento2, setConsentimiento2] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        const url = e.target.action;

        var mensajeerror = "";

        if (nombrecontacto === "" || telefono === "" || email === "" || mensaje === "" || localidad === "" || !consentimiento1 || !consentimiento2) {

            if (nombrecontacto === "") mensajeerror += `${t("Nombre Contacto")}<br/>`;
            if (telefono === "") mensajeerror += `${t('Teléfono')}<br/>`;
            if (email === "") mensajeerror += `${t('Email')}<br/>`;
            if (localidad === "") mensajeerror += `${t('Localidad')}<br/>`;
            if (mensaje === "") mensajeerror += `${t('Mensaje')}<br/>`;
            if (!consentimiento1) mensajeerror += `${t("consentimiento 1")}<br/>`;
            if (!consentimiento2) mensajeerror += `${t("consentimiento 2")}<br/>`;

            Swal.fire({
                title: `${t('Error')}`,
                html: `${t('Todos los campos con *  obligatorios')}<br/>` + mensajeerror,
                timer: 2500,
                showConfirmButton: false,
                allowOutsideClick: false,
                allowEscapeClick: false
            });
        } else {
            let elnombre = nombrecontacto;
            let eltelefono = telefono;
            let elmail = email;
            let elmensaje = mensaje;
            let elidioma = document.getElementById("idioma").value;
            let elfax = fax;
            let laempresa = empresa;
            let lalocalidad = localidad;
            let laprovincia = provincia;
            let laactividad = actividad;

            let token = document.getElementById('meta_token').getAttribute('content');
            const result = axios.post(url, {
                nombrecontacto: elnombre,
                telefono: eltelefono,
                emailContacto: elmail,
                contactMessage: elmensaje,
                fax: elfax,
                laempresa: laempresa,
                localidad: lalocalidad,
                provincia: laprovincia,
                actividad: laactividad,
                lang: elidioma
            }, {
                headers: {
                    'content-type': 'text/json',
                    'X-CSRF-TOKEN': token
                }
            })
                .then((response) => {
                    if (response.data.type === "error") {
                        Swal.fire({
                            title: "KO!",
                            html: `${t('Contacto no enviado. Inténtalo más tarde')}`,
                            timer: 1500,
                            showConfirmButton: false,
                            allowOutsideClick: false,
                        });
                    } else {
                        Swal.fire({
                            title: "OK!",
                            html: `${t('Contacto enviado. En breve te informaremos')}`,
                            timer: 1500,
                            showConfirmButton: false,
                            allowOutsideClick: false,
                        }).then((result) => {
                            window.location.reload();
                        });
                    }
                });
        }
    };

    return (
        <>
            {/*--===== CONTACT AREA STARTS =======--*/}
            <div className="contact-inner-area sp6">
                <div className="container">
                    <div className="row">
                        <div className="m-auto col-lg-2">
                            <h5 data-aos="fade-left" data-aos-duration="800" className="aos-init aos-animate tituloredondeado">{t("Asóciate")}</h5>
                        </div>
                    </div>
                    <div className="space40"></div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="contact-inner-boxarea">
                                <div className="row align-items-center">
                                    <div className="col-lg-12">
                                        <div className="contact-input-section">
                                            <h3>{t("Envíanos un mensaje")}</h3>
                                            <div className="space16"></div>
                                            <div className="space12"></div>
                                            <form className="noticia-individual" id="contactForm" action="/enviacontactoasociar" onSubmit={handleSubmit}>
                                                <input type="hidden" name="lang" id="idioma" value={lang.toString().toUpperCase()} />
                                                <div className="row">
                                                    <div className="col-lg-6">
                                                        <div className="space20"></div>
                                                        <div className="input-area">
                                                            <input type="text" placeholder={t("Persona de contacto") + " *"} name="yourname" required="" value={nombrecontacto} onChange={(e) => setNombreContacto(e.target.value)} />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="space20"></div>
                                                        <div className="input-area">
                                                            <input type="number" placeholder={t("Teléfono") + " *"} name="Phone" required="" value={telefono} onChange={(e) => setTelefono(e.target.value)} />
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-6">
                                                        <div className="space20"></div>
                                                        <div className="input-area">
                                                            <input type="email" placeholder={t("Email") + " *"} name="contactEmail" required="" value={email} onChange={(e) => setEmail(e.target.value)} />
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-6">
                                                        <div className="space20"></div>
                                                        <div className="input-area">
                                                            <input type="number" placeholder={t("Fax")} name="contactFax" value={fax} onChange={(e) => setFax(e.target.value)} />
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-6">
                                                        <div className="space20"></div>
                                                        <div className="input-area">
                                                            <input type="text" placeholder={t("Empresa")} name="contactEmpresa" value={empresa} onChange={(e) => setEmpresa(e.target.value)} />
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-6">
                                                        <div className="space20"></div>
                                                        <div className="input-area">
                                                            <input type="text" placeholder={t("Localidad") + " *"} name="localidad" value={localidad} onChange={(e) => setLocalidad(e.target.value)} />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="space20"></div>
                                                        <div className="input-area">
                                                            <input type="text" placeholder={t("Provincia")} name="provincia" value={provincia} onChange={(e) => setProvincia(e.target.value)} />
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-6">
                                                        <div className="space20"></div>
                                                        <div className="input-area">
                                                            <input type="text" placeholder={t("Actividad")} name="actividad" value={actividad} onChange={(e) => setActividad(e.target.value)} />
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-12">
                                                        <div className="space20"></div>
                                                        <div className="input-area">
                                                            <textarea placeholder={t("Mensaje") + " *"} name="contactMessage" required="" value={mensaje} onChange={(e) => setMensaje(e.target.value)}></textarea>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="space32"></div>
                                                        <div className="input-area" style={{ float: "left" }}>
                                                            <input type="checkbox" value="acepto1" name="acepto1" required="" checked={consentimiento1} onChange={(e) => setConsentimiento1(e.target.checked)} className="checkboxpersonalizado" /> {t("MANIFIESTO que he leido y ACEPTO el contenido de la información precedene relativa a la protección de datos de caracter personal")}
                                                        </div>
                                                        <div className="space32"></div>
                                                        <div className="input-area" style={{ float: "left" }}>
                                                            <input type="checkbox" value="acepto2" name="acepto2" required="" checked={consentimiento2} onChange={(e) => setConsentimiento2(e.target.checked)} className="checkboxpersonalizado" /> {t("MANIFIESTO que he leido y ACEPTO las condiciones de uso y política de privacidad")}
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-12">
                                                        <div className="space32"></div>
                                                        <div className="input-area text-end">
                                                            <button type="submit" className="header-btn4">{t("Enviar")}</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <h3>{t("Cómo asociarse")}</h3>
                                        <div className="space20"></div>
                                        <p>{t("Te invitamos a formar parte de nuestro proyecto, un proyecto que hará que estés mejor situado y con unos servicios muy ventajosos para tu negocio en el mercado turístico. Para formar parte de nuestro equipo, AT Costa Daurada, únicamente tienes que estar dado de alta en turismo como empresa de alquiler o desde nuestra asociación podemos asesorarle de la documentación para legalizarte. Uno de nuestros activos más destacados es la atención personalizada a los asociados, que la lleva a cabo un equipo de profesionales preparado para atender sus consultas. A parte, de lo que les podemos solucionar y ayudar, directamente, a través de una llamada telefónica o por correo electrónico.")}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="space80"></div>
                </div>
            </div>
            {/*--===== CONTACT AREA ENDS =======--*/}
        </>
    )
}
