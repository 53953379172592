import React from "react";
import { Container } from "react-bootstrap";

import { useTranslation } from 'react-i18next';

import ReactHtmlParser from 'react-html-parser';

const ListaCaracteristicas = ({ caracteristicas, config }) => {

    const { t } = useTranslation();

    return (
        <>
            <div className="text-center row">
                <div className="col-md-8 offset-md-2">
                    <h2>{config.nombre_comercial}</h2>
                    <p className="mb-5 font-italic">{t("¿Por_qué_reservar_con_nosotros?")}</p>
                </div>
            </div>
            <div className="row gx-lg-5">
                {caracteristicas.map((caracteristica, index) => (
                    <div className="mb-5 col-lg-6 col-xxl-4" id={index} key={index}>
                        <div className="bg-white border-0 shadow card bg-light h-100 br15">
                            <div className="p-4 pt-0 text-center card-body p-lg-5 pt-lg-0">
                                <div className="mb-5 caracteristica">
                                    <i className={"fa-duotone " + caracteristica['imagen'] + " fa-2x"}></i>
                                </div>
                                <h2 className="fs-4 fw-bold">{caracteristica['elnombre']}</h2>
                                <div className="mb-0">
                                    {ReactHtmlParser(caracteristica.ladesc)}
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </>
    )
}

const Caracteristicas = ({ caracteristicas, config }) => {

    return (
        caracteristicas.length > 0 ?
            <div className="pt-4 pb-5 mb-5">
                <Container>
                    <ListaCaracteristicas
                        caracteristicas={caracteristicas}
                        config={config}
                    />
                </Container>
            </div>
            :
            <></>
    );
}

export default Caracteristicas;
