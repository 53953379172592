import { useTranslation } from "react-i18next";

export default function Propiedades({ propiedades, ruta, lang, tiposcasas, poblaciones, tipocasa, poblacion, capacidad = 1 }) {

    const { t } = useTranslation();

    var preidioma = ""
    if (lang != 'es') preidioma = lang + "/";

    return (
        <>
            {/*--===== APARTMENT AREA STARTS =======--*/}
            <div className="apartment-inner2-section-area sp7 bg2">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9">
                            <form method="get" action={"/" + preidioma + t("alquiler")}>
                                <div className="apartment-list-area space-margin60">
                                    <div className="select-area">
                                        <select name="tipo" className="nice-select">
                                            <option value="">{t("Tipo Alojamiento")}</option>
                                            {tiposcasas.map((untipocasa, index) => (
                                                (untipocasa.id == tipocasa) ?
                                                    <option key={index} value={untipocasa.id} selected>{t(untipocasa.nombre)}</option>
                                                    :
                                                    <option key={index} value={untipocasa.id}>{t(untipocasa.nombre)}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="select-area2">
                                        <select name="poblacion" className="nice-select">
                                            <option value="">{t("Población")}</option>
                                            {poblaciones.map((unapoblacion, index) => (
                                                (unapoblacion == poblacion) ?
                                                    <option key={index} value={unapoblacion} selected>{unapoblacion}</option>
                                                    :
                                                    <option key={index} value={unapoblacion}>{unapoblacion}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="input-area">
                                        <input type="number" name="capacidad" placeholder={t("Capacidad")} defaultValue={capacidad}></input>
                                    </div>
                                    <div className="btn-area1">
                                        <button type="submit" className="header-btn4">{t("Buscar")}</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="space40"></div>
                    <div className="row" style={{ textAlign: "center" }}>
                        <div className="m-auto col-lg-2">
                            <h5 data-aos="fade-left" data-aos-duration="800" className="aos-init aos-animate tituloredondeado">{t("Alquiler")}</h5>
                        </div>
                    </div>
                    <div className="space40"></div>
                    <div className="row">
                        {
                            propiedades.map((propiedad, index) => (
                                <div className="col-lg-4 col-md-6" data-aos="zoom-in" data-aos-duration={800 + (index * 100)} key={index}>
                                    <div className="apartment-boxarea">
                                        <div className="img1">
                                            {
                                                propiedad.foto_principal && propiedad.foto_principal.length != 0
                                                    ? <img src={ruta + "/propiedades/" + propiedad.id + "/fotos/web/thumb/" + propiedad.foto_principal[0].nombre} alt={propiedad.foto_principal[0].texto_alt ? propiedad.foto_principal[0].texto_alt[lang] : ""} title={propiedad.foto_principal[0].texto_title ? propiedad.foto_principal[0].texto_title[lang] : ""} />
                                                    : <img src="/images/web/bedloop-nofoto.png" alt="" title="" />
                                            }
                                        </div>
                                        <div className="content-area">
                                            <a href={"/" + preidioma + t("apartamentos") + "-" + propiedad.destino.url_dinamica[lang] + "/" + propiedad.url_dinamica}>
                                                {propiedad.nombre}
                                            </a>
                                            <div className="space16"></div>
                                            <ul>
                                                <li><i className="fa-solid fa-home texto-azul"></i> {propiedad.tipo_apartamento.nombre} <span> | </span></li>
                                                <li><i className="fa-solid fa-globe texto-azul"></i> {propiedad.ciudad} <span> | </span></li>
                                            </ul>
                                            <div className="space8"></div>
                                            <ul>
                                                <li><i className="fa-solid fa-bed texto-azul"></i> {propiedad.num_habitaciones} <span> | </span></li>
                                                <li><i className="fa-solid fa-users texto-azul"></i> {propiedad.capacidad_maxima} <span> | </span></li>
                                                <li><i className="fa-solid fa-bath texto-azul"></i> {propiedad.num_banyos}</li>
                                            </ul>
                                            <div className="space16"></div>
                                            <a className="enlacemasinfo" href={"/" + preidioma + t("apartamentos") + "-" + propiedad.destino.url_dinamica[lang] + "/" + propiedad.url_dinamica}>{t("Más información")} <i className="fa-solid fa-arrow-right"></i></a>
                                        </div>
                                        <div className="arrow">
                                            <a href={"/" + preidioma + t("apartamentos") + "-" + propiedad.destino.url_dinamica[lang] + "/" + propiedad.url_dinamica} style={{ fontSize: "14px" }}>{t("Ver")}</a>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
            {/*--===== APARTMENT AREA ENDS =======--*/}
        </>
    )
}
