import { React } from "react";
import { useTranslation } from 'react-i18next';
import { useState } from "react";
import axios from 'axios';
import Swal from 'sweetalert2';
import 'react-dropzone-uploader/dist/styles.css'
import Dropzone from 'react-dropzone-uploader'


export default function FormularioBolsaTrabajo() {

    const { t } = useTranslation();

    const [nombre, setNombre] = useState('');
    const [telefono, setTelefono] = useState('');
    const [consentimiento, setConsentimiento] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        const url = e.target.action;

        var mensajeerror = "";

        if (nombre === "" || telefono === "" || !consentimiento) {

            if (nombre === "") mensajeerror += `${t("Nombre")}<br/>`;
            if (telefono === "") mensajeerror += `${t('Teléfono')}<br/>`;
            if (!consentimiento) mensajeerror += `${t("consentimiento")}<br/>`;

            Swal.fire({
                title: `${t('Error')}`,
                html: `${t('Todos los campos con * son obligatorios')}<br/>` + mensajeerror,
                timer: 2500,
                showConfirmButton: false,
                allowOutsideClick: false,
                allowEscapeClick: false
            });
        } else {
            let elnombre = nombre;
            let eltelefono = telefono;

            let token = document.getElementById('meta_token').getAttribute('content');
            const result = axios.post(url, {
                yourname: elnombre,
                Phone: eltelefono,
            }, {
                headers: {
                    'content-type': 'text/json',
                    'X-CSRF-TOKEN': token
                }
            })
                .then((response) => {
                    console.log(response);
                    if (response.data.type === "error") {
                        Swal.fire({
                            title: "KO!",
                            html: `${t('Contacto no enviado. Inténtalo más tarde')}`,
                            timer: 1500,
                            showConfirmButton: false,
                            allowOutsideClick: false,
                        });
                    } else {
                        Swal.fire({
                            title: "OK!",
                            html: `${t('Contacto enviado. Te informaremos en breve')}`,
                            timer: 1500,
                            showConfirmButton: false,
                            allowOutsideClick: false,
                        }).then((result) => {
                            window.location.reload();
                        });
                    }
                });
        }
    };

    // specify upload params and url for your files
    const getUploadParams = ({ file, meta }) => {
        const body = new FormData()
        body.append('fileField', file);
        return { url: 'https://httpbin.org/post', body }
    }

    // called every time a file's `status` changes
    const handleChangeStatus = ({ meta, file }, status) => { }

    // receives array of files that are done uploading when submit button is clicked
    const handleSubmitCV = (files, allFiles) => {

        const formData = new FormData();
        formData.append('file', files[0]['file']);

        let token = document.getElementById('meta_token').getAttribute('content');
        const result = axios.post('/uploadcv', formData,
            {
                headers: {
                    'X-CSRF-TOKEN': token,
                    'Content-Type': 'multipart/form-data'
                }
            })
        allFiles.forEach(f => f.remove())
    }

    return (
        <>
            <div className="contact-inner-area sp4">
                <div className="container">
                    <div className="row" style={{ textAlign: "center" }}>
                        <div className="m-auto col-lg-5">
                            <h5 data-aos="fade-left" data-aos-duration="800" className="aos-init aos-animate tituloredondeado">{t("Envía tu Currículum")}</h5>
                        </div>
                    </div>
                    <div className="space40"></div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="contact-inner-boxarea">
                                <div className="row align-items-center">
                                    <div className="col-lg-12">
                                        <div className="contact-input-section">
                                            <div className="space12"></div>
                                            <form className="noticia-individual" id="contactForm" action="/enviabolsatrabajo" onSubmit={handleSubmit}>
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="space20"></div>
                                                        <div className="input-area">
                                                            <input type="text" placeholder={t("Tu nombre")} name="yourname" required="" value={nombre} onChange={(e) => setNombre(e.target.value)} />
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-12">
                                                        <div className="space20"></div>
                                                        <div className="input-area">
                                                            <input type="number" placeholder={t("Tu teléfono")} name="Phone" required="" value={telefono} onChange={(e) => setTelefono(e.target.value)} />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="space20"></div>
                                                        <Dropzone
                                                            getUploadParams={getUploadParams}
                                                            onChangeStatus={handleChangeStatus}
                                                            onSubmit={handleSubmitCV}
                                                            accept=".pdf"
                                                            inputContent={t("Arrastra archivo PDF o presiona aquí para subirlo")}
                                                            submitButtonContent={t("Subir")}
                                                            maxFiles="1"
                                                        />
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="space32"></div>
                                                        <div className="input-area">
                                                            <input type="checkbox" value="acepto" name="acepto" required="" checked={consentimiento} onChange={(e) => setConsentimiento(e.target.checked)} className="checkboxpersonalizado" /> <a href="condiciones">{t("Acepto los términos y condiciones")}</a>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="space32"></div>
                                                        <div className="input-area text-end">
                                                            <button type="submit" className="header-btn4">{t("Enviar")}</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
