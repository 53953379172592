import { useTranslation } from "react-i18next";
import ReactHtmlParser from 'react-html-parser';

export default function Junta({ personas, ruta }) {

    const { t } = useTranslation();

    return (
        <>
            {/*--===== BLOG AREA STARTS =======--*/}
            <div className="blog6-section-area sp4">
                <div className="container">
                    <div className="row">
                        <div className="m-auto col-lg-5">
                            <div className="text-center blog-header heading6">
                                <h5 data-aos="fade-left" data-aos-duration="800">{t("Órganos de Gobierno")}</h5>
                                <div className="space20"></div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="m-auto col-lg-7">
                            <div className="blog-author-area">
                                <div className="space40"></div>
                                <h3>{t("Asamblea General")}</h3><br />
                                <p>{t("La Asamblea General constituye el cuerpo decisorio supremo de AAT Costa Daurada. Integrada por la totalidad de los asociados, se convoca anualmente. En este foro, todos los miembros ejercen el derecho al voto y a la participación, bajo condiciones de plena igualdad.")}</p>
                                <p>{t("Las atribuciones primordiales de la Asamblea General son: la elección de la Junta Directiva, la aprobación de los presupuestos y la definición de las estrategias generales que orientarán la actuación de la asociación.")}</p><br />
                                <h3>{t("Junta Directiva")}</h3><br />
                                <p>{t("La Junta Directiva está conformada por un equipo de expertos con una sólida trayectoria en el ámbito turístico. Su mandato tiene una duración cuatrienal. Las funciones encomendadas a la Junta Directiva abarcan: la dirección y gestión de la asociación, la representación institucional, la administración de los recursos financieros y el velamiento por el cumplimiento de los acuerdos adoptados por la Asamblea General.")}</p>
                                <div className="space16"></div>
                            </div>
                        </div>
                    </div>
                    <div className="space40"></div>
                    <div className="row">
                        {
                            personas.map((persona, index) => (
                                <div className="col-lg-4 col-md-6" data-aos="zoom-in-up" data-aos-duration={800 + (index * 200)} key={index}>
                                    <div className="blog-boxarea">
                                        <div className="img1 image-anime">
                                            <img src={ruta + "/quienesSomos/" + persona.id + "/" + persona.imagen} />
                                        </div>
                                        <div className="content-area">
                                            <a>{persona.nombre}</a>
                                            <div className="space24"></div>
                                            {ReactHtmlParser(persona.ladesc)}
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
            {/*--===== BLOG AREA ENDS =======--*/}
        </>
    )
}
