import { React } from "react";
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import Swal from 'sweetalert2';

export default function FormularioBolsaTrabajoUsuario() {

    const { t } = useTranslation();

    const cookieValue = document.cookie.split('; ').filter(row => row.startsWith('nombre=')).map(c => c.split('=')[1])[0];

    const handleSubmit = (e) => {

        e.preventDefault();
        const url = e.target.action;

        let token = document.getElementById('meta_token').getAttribute('content');
        var checkBoxes = document.querySelectorAll('.preferencia');

        let resultado = "";

        checkBoxes.forEach(item => {
            if (item.checked) {
                if (resultado !== "")
                    resultado += "_" + item.value;
                else
                    resultado += item.value;
            }
        })

        if (resultado !== "") {
            const formData = new FormData();
            formData.append("nombre", cookieValue);
            formData.append("preferencias", resultado);

            const result = axios.post(url, formData,
                {
                    headers: {
                        'X-CSRF-TOKEN': token,
                        'content-type': 'text/json',
                    }
                })
                .then((response) => {
                    if (response.data.type === "error") {
                        Swal.fire({
                            title: "¡Error!",
                            html: `${t('Preferencias no enviadas. Inténtalo más tarde')}`,
                            timer: 1500,
                            showConfirmButton: false,
                            allowOutsideClick: false,
                        });
                    } else {
                        Swal.fire({
                            title: "¡OK!",
                            html: `${t('Preferencias enviadas en breve te informaremos')}`,
                            timer: 1500,
                            showConfirmButton: false,
                            allowOutsideClick: false,
                        }).then((result) => {
                            window.location.reload();
                        });
                    }
                });
        }
        else {
            Swal.fire({
                title: "¡Error!",
                html: `${t('Debes escoger almenos un area de interés')}`,
                timer: 1500,
                showConfirmButton: false,
                allowOutsideClick: false,
            });
        }
    }

    return (
        <>
            <div className="contact-inner-area sp4">
                <div className="container">
                    <div className="row" style={{ textAlign: "center" }}>
                        <div className="m-auto col-lg-5">
                            <h5 data-aos="fade-left" data-aos-duration="800" className="aos-init aos-animate tituloredondeado">{t("¿Qué preferéncias tienes?")}</h5>
                        </div>
                    </div>
                    <div className="space40"></div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="contact-inner-boxarea">
                                <div className="row align-items-center">
                                    <div className="col-lg-12">
                                        <div className="contact-input-section">
                                            <h3>{t("Escoge de que areas quieres recibir información")}:</h3>
                                            <div className="space16"></div>
                                            <form className="noticia-individual" id="contactForm" action="/enviabolsatrabajousuario" onSubmit={handleSubmit}>
                                                <div className="row">
                                                    <div className="col-lg-3">
                                                        <div className="space20"></div>
                                                        <div className="input-area align-initial">
                                                            <input type="checkbox" name="preferencia[]" value="Recepcion" className="checkboxpersonalizado preferencia" /> <i className="fa-duotone fa-bell-concierge fa-2x"></i> {t("Recepción")}
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3">
                                                        <div className="space20"></div>
                                                        <div className="input-area align-initial">
                                                            <input type="checkbox" name="preferencia[]" value="Agente de reservas" className="checkboxpersonalizado preferencia" /> <i className="fa-duotone fa-book-user fa-2x"></i> {t("Agente Reservas")}
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3">
                                                        <div className="space20"></div>
                                                        <div className="input-area align-initial">
                                                            <input type="checkbox" name="preferencia[]" value="Administración-Administratiuvo/va" className="checkboxpersonalizado preferencia" /> <i className="fa-duotone fa-user-pen fa-2x"></i> {t("Administrativo/va")}
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3">
                                                        <div className="space20"></div>
                                                        <div className="input-area align-initial">
                                                            <input type="checkbox" name="preferencia[]" value="Comercial" className="checkboxpersonalizado preferencia" /> <i className="fa-duotone fa-briefcase fa-2x"></i> {t("Comercial")}
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3">
                                                        <div className="space20"></div>
                                                        <div className="input-area align-initial">
                                                            <input type="checkbox" name="preferencia[]" value="Camarera de pisos" className="checkboxpersonalizado preferencia" /> <i className="fa-duotone fa-vacuum fa-2x"></i> {t("Camarera de pisos")}
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3">
                                                        <div className="space20"></div>
                                                        <div className="input-area align-initial">
                                                            <input type="checkbox" name="preferencia[]" value="Gobernanta" className="checkboxpersonalizado preferencia" /> <i className="fa-duotone fa-user-gear fa-2x"></i> {t("Gobernanta")}
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-3">
                                                        <div className="space20"></div>
                                                        <div className="input-area align-initial">
                                                            <input type="checkbox" name="preferencia[]" value="Lavandería" className="checkboxpersonalizado preferencia" /> <i className="fa-duotone fa-washing-machine fa-2x"></i> {t("Lavandería")}
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-3">
                                                        <div className="space20"></div>
                                                        <div className="input-area align-initial">
                                                            <input type="checkbox" name="preferencia[]" value="Mantenimiento" className="checkboxpersonalizado preferencia" /> <i className="fa-duotone fa-wrench-simple fa-2x"></i> {t("Mantenimiento")}
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-3">
                                                        <div className="space20"></div>
                                                        <div className="input-area align-initial">
                                                            <input type="checkbox" name="preferencia[]" value="Conserje" className="checkboxpersonalizado preferencia" /> <i className="fa-duotone fa-user-tie fa-2x"></i> {t("Conserje")}
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-3">
                                                        <div className="space20"></div>
                                                        <div className="input-area align-initial">
                                                            <input type="checkbox" name="preferencia[]" value="Animación" className="checkboxpersonalizado preferencia" /> <i className="fa-duotone fa-user-music fa-2x"></i> {t("Animación")}
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-3">
                                                        <div className="space20"></div>
                                                        <div className="input-area align-initial">
                                                            <input type="checkbox" name="preferencia[]" value="Cocina" className="checkboxpersonalizado preferencia" /> <i className="fa-duotone fa-hat-chef fa-2x"></i> {t("Cocina")}
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-3">
                                                        <div className="space20"></div>
                                                        <div className="input-area align-initial">
                                                            <input type="checkbox" name="preferencia[]" value="Sala" className="checkboxpersonalizado preferencia" /> <i className="fa-duotone fa-house-building fa-2x"></i> {t("Sala")}
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="space32"></div>
                                                        <div className="input-area text-end">
                                                            <button type="submit" className="header-btn4">{t("Enviar")}</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
