import { useTranslation } from "react-i18next";

export default function Noticias({ noticias, ruta, lang }) {

    const { t } = useTranslation();

    var preidioma = ""
    if (lang != 'es') preidioma = lang + "/";

    return (
        <>
            {/*--===== BLOG AREA STARTS =======--*/}
            <div className="blog3-section-area sp7">
                <div className="container">
                    <div className="row">
                        <div className="m-auto col-lg-12">
                            <div className="text-center blog-header heading3 space-margin60">
                                <h5 data-aos="fade-left" data-aos-duration="800" style={{ lineHeight: "30px" }}>{t("Asociación de apartamentos turísticos Costa Daurada y Terres de l’Ebre")}</h5>
                                <div className="space20"></div>
                                <h2 className="text-anime-style-3">{t("Actividades")}</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        {
                            noticias.map((noticia, index) => (
                                <div className="col-lg-4 col-md-6" data-aos="zoom-in-up" data-aos-duration={800 + (index * 200)} key={index}>
                                    <div className="blog-boxarea">
                                        <div className="img1 image-anime">
                                            {
                                                noticia.imagen != ""
                                                    ? <img src={ruta + "/blog/" + noticia.id + "/" + noticia.imagen} alt="" />
                                                    : <img src="/images/web/bedloop-nofoto.png" alt="" />
                                            }
                                        </div>
                                        <div className="content-area">
                                            <ul>
                                                <li><a href="#"><i className="fa-regular fa-calendar texto-azul"></i> {new Date(noticia.fecha_publica).toLocaleDateString()} </a></li>
                                            </ul>
                                            <div className="space20"></div>
                                            <a href={"/" + preidioma + t("blog") + "/" + noticia.laurl}>{noticia.eltitulo}</a>
                                            <div className="space24"></div>
                                            <a href={"/" + preidioma + t("blog") + "/" + noticia.laurl} className="readmore">{t("Leer más")} <i className="fa-solid fa-arrow-right"></i></a>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div >
            {/*--===== BLOG AREA ENDS =======--*/}
        </>
    )
}
