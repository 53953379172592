import { React } from "react";
import { Col, Nav, Row, Tab, Accordion } from 'react-bootstrap';

import { useTranslation } from 'react-i18next';
import ReactHtmlParser from 'react-html-parser';

const TextosFaqs = ({ categorias, faqs, config, lang, webconfig }) => {

    const { t } = useTranslation();

    return (
        <div className="container">
            <Tab.Container id="left-tabs-example" defaultActiveKey="1">
                <Row>
                    <h2 className="mb-4 text-center">{t("Hola, ¿cómo podemos ayudarle?")}</h2>
                    <Col sm={3}>
                        <Nav variant="pills" className="rounded shadow-lg flex-column pillsfaqs">
                            {categorias.map((categoria, index) => (
                                <Nav.Item key={"cat" + index}>
                                    <Nav.Link eventKey={index + 1}>{categoria.nombre[lang]}</Nav.Link>
                                </Nav.Item>
                            ))}
                        </Nav>
                    </Col>
                    <Col sm={9} className="mt-2">
                        <Tab.Content>
                            {categorias.map((categoria, index) => (
                                <Tab.Pane eventKey={index + 1} key={"cf" + index}>
                                    <Accordion>
                                        {faqs.filter(faq => faq.categoria_id == categoria.id).map((faq, index2) => (
                                            <Accordion.Item eventKey={index + "" + index2} key={"f" + index2}>
                                                <Accordion.Header >{faq.pregunta[lang]}</Accordion.Header>
                                                <Accordion.Body>
                                                    <div>
                                                        {ReactHtmlParser(faq.respuesta.lang)}
                                                    </div>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        ))}
                                    </Accordion>
                                </Tab.Pane>
                            ))}
                        </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container>
            <div className="mt-5 linia"></div>
            <div className="mb-5 row noticia-individual-descripcio-llarga">
                <h3 className="mt-5 text-center"> {t("¿No encuentra lo que busca? Aquí tiene algunas opciones")}</h3>

                <div className="shadow-lg featured-box">
                    <div className="icon bg-primary">
                        <i className="fa fa-comments"></i>
                    </div>
                    <div className="content">
                        <h4 className="uppercase">{t("Contacto")}</h4>
                        <p>
                            {webconfig.nombre_comercial}<br />
                            {webconfig.direccion_fiscal}<br />
                            {webconfig.email_fiscal}<br />
                            {webconfig.telefono_fiscal}
                        </p>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default TextosFaqs;
