import { React, useEffect, useState } from "react";
import { Row, Col, NavDropdown, Container, Navbar, Nav, DropdownDivider } from "react-bootstrap";
import { DropdownSubmenu, NavDropdownMenu } from "react-bootstrap-submenu";

import { useTranslation } from 'react-i18next';

import LangSelector, { langSelector } from './LangSelector.jsx';

const Menu = ({ destinos, sobrenosotros, clientes, propietarios, servicios, experiencias, idiomas, lang, tiposcasas, config, textopropiedades, edificios, marcas, ruta, destinoslarga, meta_titulo, meta_descripcion, meta_keywords, numblog, numfaqs, paginapersonalizada }) => {
    const { t } = useTranslation();

    var preidioma = ""
    if (lang != 'es') preidioma = lang + "/";

    const primeraLetraMayuscula = (cadena) => cadena.charAt(0).toUpperCase().concat(cadena.substring(1, cadena.length).toLowerCase());

    useEffect(() => {
        document.title = meta_titulo
        let titleMeta = document.querySelector("meta[name='title']")
        titleMeta.setAttribute("content", meta_titulo)

        let descMeta = document.querySelector("meta[name='description']")
        descMeta.setAttribute("content", meta_descripcion)

        let keyMeta = document.querySelector("meta[name='keywords']")
        keyMeta.setAttribute("content", meta_keywords)

        if (config.id === 1) {
            let favicon = ruta + "/web/favicon/favicon.ico"
            let fav = document.querySelector("link[rel='icon']")
            fav.setAttribute("href", favicon)
        }
        else {
            let favicon = ruta + "/web/favicon/" + config.id + "/favicon.ico"
            let fav = document.querySelector("link[rel='icon']")
            fav.setAttribute("href", favicon)
        }

        if (config.css_personalizado) {
            const linkElement = document.createElement('link');
            linkElement.rel = 'stylesheet';
            linkElement.type = 'text/css';
            linkElement.href = config.css_personalizado;
            const headElement = document.head;
            headElement.appendChild(linkElement);
        }

    }, []);

    return (
        <Navbar collapseOnSelect expand="lg" className="bg-body sticky-top shadow p-3 mb-5 bg-body">
    <Container className="d-flex align-items-center">
        <Navbar.Brand href="/" className="">
            {config.logo_fiscal !== "" ? (
                <img
                    className="logo logobl1"
                    alt="Logo"
                    title="Logo"
                    src={`${ruta}/web/logo/${config.logo_fiscal}`}
                />
            ) : (
                <img
                    className="logo logobl1"
                    alt="Logo BedLoop"
                    title="Logo BedLoop"
                    src="https://www.bedloop.com/imatges/logo.webp"
                />
            )}
        </Navbar.Brand>
        <div className="d-flex align-items-center">
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="">
                    <NavDropdown
                        className="navegacio"
                        title={t("Ayuda")}
                        id="collasible-nav-dropdown.ayuda"
                    >
                        <Container className="mt-1 mb-1 ajuda">
                            <Row className="ml-5">
                                <Col xs="12" md="12" sm="6" className="mt-3">
                                    <p className="bold">{t("¿Alguna pregunta? ¡Hablemos!")}</p>
                                </Col>
                                <Col xs="12" md="12" sm="6">
                                    <i className="fa-duotone fa-square-phone"></i> {config.telefono_fiscal}<br /><br />
                                    <i className="fa-duotone fa-square-envelope" aria-hidden="true"></i> {config.email_fiscal}<br /><br />
                                    <a href="#"><i className="fa-duotone fa-location-dot" aria-hidden="true"></i> {config.direccion_fiscal + ", " + config.codigo_postal_fiscal + ", " + config.poblacion_fiscal}</a>
                                </Col>
                            </Row>
                        </Container>
                    </NavDropdown>
                    <LangSelector
                        idiomas={idiomas}
                        langactual={lang.toString().toUpperCase()}
                    />
                    {/*<Nav.Link className="navegacio" href="/loginweb">{ t("Login") }</Nav.Link>*/}
                </Nav>
            </Navbar.Collapse>
        </div>
    </Container>
</Navbar>
    );
}

export default Menu;
