import { useTranslation } from "react-i18next"

export default function Carousel2({ destinos, d_ale, ruta, tiposcasas, poblaciones, lang }) {

    const { t } = useTranslation();

    const imagenes = destinos[d_ale].imagenes;

    const imagen = ruta + '/destinos/' + destinos[d_ale].id + '/' + imagenes[0].imagen

    var preidioma = ""
    if (lang != 'es') preidioma = lang + "/";



    return (
        <>
            {/*--===== HERO AREA STARTS =======--*/}
            <div className="hero10-section-area" style={{ backgroundImage: `url(${imagen})` }}>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-5">
                            <div className="hero10-header">
                                <div className="space20"></div>
                                <h2 className="text-anime-style-2">{t("¿En qué podemos ayudarte?")}</h2>
                                <div className="space32"></div>
                                <div className="btn-area1" data-aos="fade-left" data-aos-duration="1000">
                                    <a href={"/" + preidioma + t("contacto")} className="header-btn11">{t("Más informacion")} <i className="fa-solid fa-arrow-right"></i></a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2"></div>
                        <div className="col-lg-5">
                            <form method="get" action={"/" + preidioma + t("alquiler")}>
                                <div className="header-contact-box" data-aos="zoom-in-up" data-aos-duration="1000">
                                    <h3>{t("Búsqueda de propiedades")}</h3>
                                    <div className="space32"></div>
                                    <div className="select-area">
                                        <select name="tipo" className="nice-select">
                                            <option value="">{t("Tipo Alojamiento")}</option>
                                            {tiposcasas.map((tipocasa, index) => (
                                                <option key={index} value={tipocasa.id}>{t(tipocasa.nombre)}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="space24"></div>
                                    <div className="select-area">
                                        <select name="poblacion" className="nice-select">
                                            <option value="">{t("Población")}</option>
                                            {poblaciones.map((poblacion, index) => (
                                                <option key={index} value={poblacion}>{poblacion}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="space24"></div>
                                    <div className="input-area">
                                        <input type="number" name="capacidad" placeholder={t("Capacidad")}></input>
                                    </div>
                                    <div className="space32"></div>
                                    <div className="input-area text-end">
                                        <button type="submit" className="header-btn11">{t("Buscar")}<i className="fa-solid fa-arrow-right"></i></button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div >
            {/*--===== HERO AREA ENDS =======--*/}

            {/*--===== OTHERS AREA STARTS =======--*/}
            <div className="others-author-area">
                <div className="container">
                    <div className="row">
                        <div className="m-auto col-lg-10">
                            <div className="auhtor-tabs-area">
                                <div className="boxes">
                                    <div className="icons" style={{ background: "#00b7d9" }}>
                                        <i className="fa-solid fa-newspaper"></i>
                                    </div>
                                    <div className="content">
                                        <a href={"/" + preidioma + t("sobre-nosotros")}>{t("Asociación")}</a>
                                    </div>
                                </div>

                                <div className="boxes">
                                    <div className="icons" style={{ background: "#adcd04" }}>
                                        <i className="fa-solid fa-map"></i>
                                    </div>
                                    <div className="content">
                                        <a href={"/" + preidioma + t("los-asociados")}>{t("Asociados")}</a>
                                    </div>
                                </div>

                                <div className="boxes">
                                    <div className="icons" style={{ background: "#fa3731" }}>
                                        <i className="fa-solid fa-ticket"></i>
                                    </div>
                                    <div className="content">
                                        <a href={"/" + preidioma + t("que-hacemos")}>{t("Servicios")}</a>
                                    </div>
                                </div>

                                <div className="boxes">
                                    <div className="icons" style={{ background: "#ffd900" }}>
                                        <i className="fa-solid fa-backpack"></i>
                                    </div>
                                    <div className="content">
                                        <a href={"/" + preidioma + t("iniciativas")}>{t("Club colaborador")}</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*--===== OTHERS AREA ENDS =======--*/}
        </>
    )
}
