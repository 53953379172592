import { useTranslation } from "react-i18next";

export default function OtraGaleria({ colaboradores, ruta }) {

    const { t } = useTranslation();

    return (
        <>
            {/*--===== GALLERY AREA STARTS =======--*/}
            <div className="gallery6-section-area sp6">
                <div className="container">
                    <div className="row">
                        <div className="m-auto col-lg-5">
                            <div className="text-center gallery-header heading6 space-margin60">
                                <h5 data-aos="fade-left" data-aos-duration="800">{t("Nuestros colaboradores")}</h5>
                                <div className="space20"></div>
                                <h2 className="text-anime-style-3">{t("Empresas")}</h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="space40"></div>
                <div className="wrapper">
                    <div className="center-slider">
                        {
                            colaboradores.map((uncolaborador, index) => {
                                return (
                                    <div key={index}>
                                        <div className="img1">
                                            <img src={ruta + "/ventajas/" + uncolaborador.id + "/" + uncolaborador.imagen} alt={uncolaborador.elnombre} />
                                        </div>
                                    </div>
                                );
                            })
                        }
                    </div>
                </div>
            </div>
            {/*--===== GALLERY AREA ENDS =======--*/}
        </>
    )
}
